<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            :label="'Title'"
            :state="inputStates('formData.title')"
            error="Title is required"
            required
          >
            <asom-input-text
              v-model="formData.title"
              :state="inputStates('formData.title')"
            />
          </asom-form-field>
          <div></div>
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <div></div>
          <asom-form-field
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Please upload only one supporting document"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createStationEBooklet"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createFaultEBooklet } from "../../../services/faultManagement.service";
import { mapGetters } from "vuex";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        title: null,
        files: [],
      },
    };
  },
  validations() {
    return {
      formData: {
        description: { required },
        title: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      lineId: "auth/userLineId",
    }),
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    createStationEBooklet() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      this.isSubmitting = true;
      const result = await createFaultEBooklet({
        title: this.formData.title,
        description: this.formData.description,
        attachmentIds: uploadedIds,
        lineId: this.lineId,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
